/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core
import { Router } from '@app';

// Core components
import { Button, Input } from '@client/components';
import useHeader from '@client/pages/useHeader';

// Local
import Page from '@/client/pages/auth/_layout/Page';


/*----------------------------------
- CONTROLEUR
----------------------------------*/
Router.page('/auth/login', { layout: 'auth' }, ({ api, request, context }) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    useHeader({
        title: "Login",
        subtitle: "Access to the CrossPath platform.",
    });

    const [data, setData] = React.useState({
        email: request.data.email ,
    });

    React.useEffect(() => {

        // Check if already logged
        // TODO

        // Restore latest used email
        if (data.email === undefined) {
            const memorisedEmail = localStorage?.getItem('auth.email');
            if (memorisedEmail && memorisedEmail !== 'undefined')
                setData(old => ({ ...old, email: memorisedEmail }));
        }
            
    }, []);

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    const login = (data: any) => api.post('/auth/login', data)
        .then((loggedUser) => {
            localStorage?.setItem('auth.email', data.email);
            context.user = loggedUser;
            window.location.href = '/';
        })

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <Page>
            
            <header class="col txt-left">
                <h1>Access to your <strong>Recruiter</strong> Workspace</h1>

                <p>
                    Such a nice day to accomplish your goals.
                </p>
            </header>

            <form class="col">
                <Input type="email" icon="at" title="Email"
                    value={data.email} onChange={email => setData(old => ({ ...old, email }))} />

                <Input type="password" icon="key" title="Password"
                    value={data.password} onChange={password => setData(old => ({ ...old, password }))} />

                <Button async type="primary" iconR="long-arrow-right" onClick={() => login(data)}>
                    Continue
                </Button>

                <Button type="link" link={"/auth/signup?email=" + data.email}>Not yet member ?</Button>

                <Button type="link" link={"/auth/login/reset?email=" + data.email}>Forgot password ?</Button>
            </form>

        </Page>
    )
});