/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';

// Resources
import IllustrationImg from './illustration.png';

/*----------------------------------
- TYPES
----------------------------------*/

export type Props = {
    children: ComponentChild
}

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ children }: Props) => {
    return (
        <div class="card bg darker row inside al-fill fill center w-a-8">

            <main class="col txt-left w-3-a">

                {children}

            </main>

            <div class="bg img bg-cover" style={{
                backgroundImage: 'url(' + IllustrationImg + ')'
            }} />

        </div>
    )
}